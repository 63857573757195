import { AddressSelectMap, Btn, Input, SearchInput, Select } from '@/components';
import { PreLoader } from '@/components/shared/atoms';
import { ValueType } from '@/components/shared/atoms/Select';
import { AddressTypeEnum, SearchInputHandle } from '@/components/shared/molecules/SearchInput';
import { cities } from '@/constants';
import useScript from '@/hooks/useScript';
import { schools } from '@/projects/school/constants';
import {
  formSelector,
  setChildCount,
  setComment,
  setFullName,
  setGrade,
  setHomeAddress,
} from '@/projects/school/store/slices/formSlice';
import { getSuggestionsAsync, schoolSelector } from '@/projects/school/store/slices/schoolSlice';
import { useAppDispatch, useAppSelector } from '@/school/app/hooks';
import { Head, Title } from '@/school/components';
import { getAddressName, getCoords } from '@/utils/address';
import { useDebounce } from '@/utils/debounce';
import { isValidNumber } from '@/utils/phone';
import { range } from '@/utils/range';
import { TAddressSource, TAddressType, tLogCustomEvent, tLogPageView, tSetCustomerIdByPhone } from '@/utils/tracking';
import { getRandomYandexAPIKey } from '@/utils/yandex';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { mainSelector, setPhone } from '@/store/slices/mainSlice';
import InputMask from 'react-input-mask';
import { batch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { setToaster } from '@/store/slices/toasterSlice';
import styles from './index.module.scss';

const grades = range(11, 1).map((num) => ({
  value: num,
  label: `${num} класс`,
}));
const childCounts = range(6, 1).map((num) => ({
  value: num,
  label: String(num),
}));

interface FormValues {
  phone: string;
  fullName: string;
  grade: ValueType;
  childCount: ValueType;
  homeAddress: AddressType | null;
  comment: string;
}

export const Form: React.FC = () => {
  const params = useParams();
  const slug = params.school;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { phone } = useAppSelector(mainSelector);
  const { grade, fullName, childCount, comment, homeAddress } = useAppSelector(formSelector);
  const { isLoading, suggestions } = useAppSelector(schoolSelector);

  const [showMap, setShowMap] = useState(false);
  const [startedTypingPickupAddress, setStartedTypingPickupAddress] = useState(false);
  const pickupAddressForwardedRef = useRef<SearchInputHandle>(null);
  const memorizedYandexAPIKey = useMemo(() => getRandomYandexAPIKey(), []);
  const initialValues: FormValues = {
    phone,
    fullName,
    grade,
    childCount,
    homeAddress,
    comment,
  };

  const onYScriptError = (error: Error) => {
    console.error('Script loading error:', error);
  };

  useScript(`https://api-maps.yandex.ru/2.1/?apikey=${memorizedYandexAPIKey}&lang=ru_RU`, onYScriptError);
  useEffect(() => tLogPageView(), []);

  const getSuggestions = (text: string): void => {
    if (text.trim() === '') return;
    if (homeAddress?.name === text) return;

    dispatch(getSuggestionsAsync(text));
  };

  const delayedGetSuggestions = useDebounce(getSuggestions, 1000);

  if (!slug || !(slug in schools)) return <>No information</>;

  const cityName = schools[slug].cityName;
  const mapCenter = cities[cityName].center;

  const onAddressSelect = async (latLng: L.LatLng) => {
    const addressObj: AddressType = {
      name: 'Точка на карте',
      location: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
    };

    try {
      const result = await getAddressName(addressObj);

      if (result.status === 'success') {
        dispatch(setHomeAddress(result.data));
        tLogCustomEvent('select_address', {
          type: TAddressType.PICKUP,
          source: TAddressSource.MAP,
          address: result.data.name,
        });
        pickupAddressForwardedRef.current!.setValue(result.data.name);
      }
    } catch {
      dispatch(
        setToaster({
          isVisible: true,
          type: 'error',
          message: 'Произошла ошибка. Попробуйте еще раз или выберите другой адрес.',
        })
      );
    }
    setShowMap(false);
  };

  const handleFormSubmit = async (values: FormValues) => {
    const address = {
      name: homeAddress?.name || '',
      location: homeAddress?.location ? { ...homeAddress.location } : null,
    };

    if (!address.location) {
      try {
        await getCoords(address);
      } catch {
        dispatch(
          setToaster({
            isVisible: true,
            type: 'error',
            message: 'Произошла ошибка. Попробуйте еще раз или выберите другой адрес.',
          })
        );
        return;
      }
    }

    setTimeout(() => {
      batch(() => {
        dispatch(setPhone(values.phone));
        dispatch(setFullName(values.fullName));
        dispatch(setGrade(values.grade));
        dispatch(setChildCount(values.childCount));
        dispatch(setComment(values.comment));
        dispatch(setHomeAddress(address));
      });
      navigate('../subscription', { relative: 'path' });
    }, 1000);
  };

  return (
    <div>
      {isLoading && <PreLoader showBackground={false} />}
      {showMap && (
        <AddressSelectMap onSelect={onAddressSelect} onBackBtnClick={() => setShowMap(false)} mapCenter={mapCenter} />
      )}
      <div className={styles.wrapper}>
        <Head small />
        <Title marginTop={16}>Заявка на развозку</Title>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleFormSubmit(values);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.phone}>
              <label htmlFor="phone">Ваш номер телефона </label>
              <InputMask
                mask="+7 (999) 999 99 99"
                placeholder="Ваш номер телефона"
                className={styles.input}
                value={values.phone}
                onChange={handleChange}
                // TODO: Maybe remove tSetCustomerIdByPhone here?
                onBlur={({ target: { value } }) => isValidNumber(value) && tSetCustomerIdByPhone(value)}
                type="tel"
                id="phone"
              />
            </div>
            <Input
              id="fullName"
              value={values.fullName}
              label="ФИО Ребенка"
              placeholder="Введите имя ребенка"
              onChange={handleChange}
            />
            <div className={styles.selects}>
              <div className={styles.select}>
                <label htmlFor="grade">Класс ребенка</label>
                <Select
                  inputClassName={styles.input}
                  id="grade"
                  options={grades}
                  value={values.grade}
                  onChange={(value) => setFieldValue('grade', value)}
                  className={styles.selectWrapper}
                  menuClassName={styles.menu}
                />
              </div>
              <div className={styles.select}>
                <label htmlFor="childCount">Кол-во детей</label>
                <Select
                  inputClassName={styles.input}
                  id="childCount"
                  options={childCounts}
                  value={values.childCount}
                  onChange={(value) => {
                    setFieldValue('childCount', value);
                  }}
                  className={styles.selectWrapper}
                  menuClassName={styles.menu}
                />
              </div>
            </div>
            <div className={styles.homeAddress}>
              <label htmlFor="home-address">Введите домашний адрес</label>
              <SearchInput
                type={AddressTypeEnum.PICKUP}
                placeholder="Домашний адрес"
                selectionData={suggestions}
                setSelectedData={(value) => {
                  dispatch(setHomeAddress(value!));
                }}
                forwardedRef={pickupAddressForwardedRef}
                value={values.homeAddress?.name || ''}
                inputClassName={styles.searchInput}
                customClassName={styles.searchInputBlock}
                closeBtnClassName={styles.searchCloseBtn}
                onChange={(value) => {
                  delayedGetSuggestions(value);
                  setFieldValue('homeAddress', {
                    name: value,
                    location: null,
                  });
                  dispatch(setHomeAddress({ name: value, location: null }));

                  // Send an event if a user started type an address.
                  if (!startedTypingPickupAddress) {
                    setStartedTypingPickupAddress(true);
                    tLogCustomEvent('started_typing_address', {
                      type: TAddressType.PICKUP,
                    });
                  }
                }}
                onBlur={() => setStartedTypingPickupAddress(false)}
                onMapBtnClick={() => setShowMap(true)}
                id="home-address"
              />
            </div>
            <Input
              id="comment"
              value={values.comment}
              label="Комментарии"
              placeholder="Уточняющая информация"
              onChange={handleChange}
            />
            <Btn
              color="black"
              isFixedBottom={true}
              onClick={handleSubmit}
              disabled={isSubmitting || !isValidNumber(values.phone) || !values.fullName || !homeAddress?.name}
            >
              Продолжить
            </Btn>
          </form>
        )}
      </Formik>
    </div>
  );
};
