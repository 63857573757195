import { Btn } from '@/components';
import { useAppDispatch, useAppSelector } from '@/shuttle/app/hooks';
import { getSubscriptionInfoAsync, subscriptionSelector } from '@/shuttle/store/slices/subscriptionSlice';
import { getDateDayString } from '@/utils/date';
import { FC, useEffect } from 'react';
import styles from './index.module.scss';

import apple from '@/assets/shuttle/badges/apple.svg';
import google from '@/assets/shuttle/badges/google.png';
import checkmark from '@/assets/shuttle/icons/checkmark.svg';
import logo from '@/assets/shuttle/img/logo.png';
import Stub from '@/components/shuttle/molecules/Stub';
import { mainSelector } from '@/store/slices/mainSlice';
import { setToaster } from '@/store/slices/toasterSlice';
import { useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    RideFinishHandler: { postMessage(name: string): void };
  }
}

type SubscriptionControllerProps = {
  subId: string;
};

const SubscriptionController: FC<SubscriptionControllerProps> = ({ subId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useAppSelector(mainSelector);
  const { data } = useAppSelector(subscriptionSelector);

  useEffect(() => {
    dispatch(
      getSubscriptionInfoAsync({
        id: subId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subId]);

  useEffect(() => {
    if (data === null) {
      dispatch(
        setToaster({
          type: 'warning',
          message: 'Подписка не найдена',
          isVisible: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function flutterEvent() {
    if (window.RideFinishHandler) {
      window.RideFinishHandler.postMessage('');
    }
  }

  return (
    <div className={styles.info}>
      {data === null ? <Stub /> : null}
      {data ? (
        <>
          <div className={styles.checkMark}>
            <img src={checkmark} alt="check-mark" />
          </div>
          <div className={styles.title}>Твое место забронировано</div>
          <div className={styles.subscription}>
            <div className={styles.subscriptionFields}>
              <div>Маршрут:</div>
              <div>Даты:</div>
            </div>
            <div className={styles.subscriptionValues}>
              <div>{data.route_name}</div>
              <div>
                {getDateDayString(new Date(Date.parse(data.valid_since_date)))}
                <span> - </span>
                {getDateDayString(new Date(Date.parse(data.valid_until_date)))}
              </div>
            </div>
            <div className={styles.subscriptionRoute}>
              {data.driver && (
                <div className={styles.driver}>
                  <div className={styles.driverPhoto}>
                    {data.driver.photo_url ? (
                      <img src={data.driver.photo_url} alt="driver" className={styles.driverPhotoImg} />
                    ) : (
                      <img src={logo} alt="driver" className={styles.driverPhotoImg} />
                    )}
                  </div>
                  <div className={styles.driverText}>
                    <div className={styles.driverTextName}>{data.driver.name}</div>
                    <div className={styles.driverTextCar}>{data.driver.car_name}</div>
                    <div className={styles.driverTextNumberPlate}>{data.driver.car_number_plate}</div>
                  </div>
                </div>
              )}
              {data.pickup_point_name && data.delivery_point_name && (
                <div className={styles.subscriptionRouteInfo}>
                  <div className={styles.timeRange}>
                    <div className={styles.timeRangeStart}>{data.pickup_point_departure_time}</div>
                    <div>{data.delivery_point_departure_time}</div>
                    <div className={styles.timeRangeStick}></div>
                  </div>
                  <div className={styles.subscriptionRouteInfoStops}>
                    <div className={styles.subscriptionRouteInfoStopsItem}>{data.pickup_point_name}</div>
                    <div className={styles.subscriptionRouteInfoStopsItem}>{data.delivery_point_name}</div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isMobile ? (
            <Btn
              color="black"
              onClick={() => {
                if (isMobile) {
                  flutterEvent();
                } else {
                  navigate(`/`);
                }
              }}
              customClassName={styles.btn}
            >
              Отлично
            </Btn>
          ) : (
            <>
              <div className={styles.callCenterBtns}>
                <a className={styles.callCenterBtn} href="tel:+77774756272">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="#fff"
                    className={styles.callCenterBtnIcon}
                  >
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                  </svg>
                  +7 777 475 62 72
                </a>
                <a className={styles.whatsAppBtn} href="http://wa.me/+77774756272">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    fill="#fff"
                    className={styles.whatsAppBtnIcon}
                  >
                    <path d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"></path>
                  </svg>
                  Написать в WhatsApp
                </a>
              </div>
              <div className={styles.badges}>
                <div className={styles.badgesTitle}>Скачать приложение</div>
                <div className={styles.badgesApple}>
                  <img
                    src={apple}
                    alt="Apple"
                    onClick={() => (window.location.href = 'https://apps.apple.com/us/app/uvu-shuttle/id6443843916')}
                  />
                </div>
                <div className={styles.badgesGoogle}>
                  <img
                    src={google}
                    alt="Google"
                    onClick={() =>
                      (window.location.href = 'https://play.google.com/store/apps/details?id=kz.uvu.shuttle')
                    }
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default SubscriptionController;
