import TourForNewCustomerController from '@/projects/shuttle/components/containers/TourForNewCustomerController';
import { tLogPageView } from '@/utils/tracking';
import { useEffect } from 'react';

const ToursForNewCustomers = () => {
  useEffect(() => {
    tLogPageView();
  }, []);

  return <TourForNewCustomerController />;
};

export default ToursForNewCustomers;
