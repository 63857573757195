import astanaGarden from './assets/img/astana-garden.jpg';
import binom from './assets/img/binom.jpg';
import miras from './assets/img/miras.jpg';
import prometheus from './assets/img/prometheus.jpg';
import quantum from './assets/img/quantum.jpg';
import rfmsh from './assets/img/rfmsh.jpg';
import tamosSpace from './assets/img/tamos-space.jpg';
import tamos from './assets/img/tamos.png';
import zaman from './assets/img/zaman.jpg';

interface Prices {
  [key: number]: number;
}

interface School {
  name: string;
  logo: string;
  schoolId: number;
  cityName: string;
  monthlyPrices: Prices;
  semiannualPrices: Prices;
  annualPrices: Prices;
}

interface SchoolRecord {
  [key: string]: School;
}

export const schools: SchoolRecord = {
  tamos: {
    name: 'Tamos',
    logo: tamos,
    schoolId: 2,
    cityName: 'almaty',
    // TODO: Write correct values
    monthlyPrices: {
      1: 38000,
      2: 68000,
      3: 100000,
      4: 135000,
      5: 165000,
      6: 195000,
    },
    semiannualPrices: {
      1: 152000,
      2: 273000,
      3: 401280,
      4: 528960,
      5: 653600,
      6: 775200,
    },
    annualPrices: {
      1: 342000,
      2: 615000,
      3: 903000,
      4: 1190000,
      5: 1470000,
      6: 1745000,
    },
  },
  'binom-zhagalau': {
    logo: binom,
    name: 'Binom имени Алихана Бокейханова',
    schoolId: 10,
    cityName: 'astana',
    monthlyPrices: {
      1: 38000,
      2: 68000,
      3: 100000,
      4: 135000,
      5: 165000,
      6: 195000,
    },
    semiannualPrices: {
      1: 152000,
      2: 273000,
      3: 401280,
      4: 528960,
      5: 653600,
      6: 775200,
    },
    annualPrices: {
      1: 342000,
      2: 615000,
      3: 903000,
      4: 1190000,
      5: 1470000,
      6: 1745000,
    },
  },
  'binom-baytirsun': {
    logo: binom,
    name: 'Binom Байтурсынулы',
    schoolId: 18,
    cityName: 'astana',
    monthlyPrices: {
      1: 38000,
      2: 68000,
      3: 100000,
      4: 135000,
      5: 165000,
      6: 195000,
    },
    semiannualPrices: {
      1: 152000,
      2: 273000,
      3: 401280,
      4: 528960,
      5: 653600,
      6: 775200,
    },
    annualPrices: {
      1: 342000,
      2: 615000,
      3: 903000,
      4: 1190000,
      5: 1470000,
      6: 1745000,
    },
  },
  'binom-altynsarin': {
    logo: binom,
    name: 'Binom имени Ыбырая Алтынсарина',
    schoolId: 28,
    cityName: 'astana',
    monthlyPrices: {
      1: 38000,
      2: 68000,
      3: 100000,
      4: 135000,
      5: 165000,
      6: 195000,
    },
    semiannualPrices: {
      1: 152000,
      2: 273000,
      3: 401280,
      4: 528960,
      5: 653600,
      6: 775200,
    },
    annualPrices: {
      1: 342000,
      2: 615000,
      3: 903000,
      4: 1190000,
      5: 1470000,
      6: 1745000,
    },
  },
  quantum: {
    logo: quantum,
    name: 'Quantum STEM',
    schoolId: 5,
    cityName: 'astana',
    monthlyPrices: {
      1: 42000,
      2: 76000,
      3: 110000,
      4: 146000,
      5: 180000,
      6: 215000,
    },
    semiannualPrices: {
      1: 168000,
      2: 302000,
      3: 443520,
      4: 584640,
      5: 722400,
      6: 856800,
    },
    annualPrices: {
      1: 378000,
      2: 680000,
      3: 998000,
      4: 1315000,
      5: 1625000,
      6: 1928000,
    },
  },
  rfmsh: {
    logo: rfmsh,
    name: 'РФМШ',
    schoolId: 6,
    cityName: 'astana',
    monthlyPrices: {
      1: 38000,
      2: 68000,
      3: 100000,
      4: 135000,
      5: 165000,
      6: 195000,
    },
    semiannualPrices: {
      1: 152000,
      2: 273000,
      3: 401280,
      4: 528960,
      5: 653600,
      6: 775200,
    },
    annualPrices: {
      1: 342000,
      2: 615000,
      3: 903000,
      4: 1190000,
      5: 1470000,
      6: 1745000,
    },
  },
  'tamos-space': {
    logo: tamosSpace,
    name: 'Tamos Space',
    schoolId: 7,
    cityName: 'astana',
    monthlyPrices: {
      1: 43000,
      2: 80000,
      3: 115000,
      4: 150000,
      5: 185000,
      6: 220000,
    },
    semiannualPrices: {
      1: 172000,
      2: 309000,
      3: 454080,
      4: 598560,
      5: 739600,
      6: 877200,
    },
    annualPrices: {
      1: 387000,
      2: 696600,
      3: 1021000,
      4: 1347000,
      5: 1664000,
      6: 1974000,
    },
  },
  'astana-garden': {
    logo: astanaGarden,
    name: 'Astana Garden School',
    schoolId: 8,
    cityName: 'astana',
    monthlyPrices: {
      1: 45000,
      2: 85000,
      3: 120000,
      4: 157000,
      5: 195000,
      6: 230000,
    },
    semiannualPrices: {
      1: 180000,
      2: 324000,
      3: 475200,
      4: 626400,
      5: 774000,
      6: 918000,
    },
    annualPrices: {
      1: 405000,
      2: 729000,
      3: 1069000,
      4: 1405000,
      5: 1740000,
      6: 2065000,
    },
  },
  zaman: {
    logo: zaman,
    name: 'Zaman School',
    schoolId: 31,
    cityName: 'almaty',
    monthlyPrices: {
      1: 40000,
      2: 74000,
      3: 111000,
      4: 148000,
      5: 185000,
      6: 222000,
    },
    semiannualPrices: {
      1: 320000,
      2: 592000,
      3: 888000,
      4: 1184000,
      5: 1480000,
      6: 1776000,
    },
    // fake values.
    annualPrices: {
      1: 320000,
      2: 592000,
      3: 888000,
      4: 1184000,
      5: 1480000,
      6: 1776000,
    },
  },
  miras: {
    logo: miras,
    name: 'Мирас',
    schoolId: 34,
    cityName: 'almaty',
    // From Astana Garden School
    monthlyPrices: {
      1: 45000,
      2: 85000,
      3: 120000,
      4: 157000,
      5: 195000,
      6: 230000,
    },
    semiannualPrices: {
      1: 180000,
      2: 324000,
      3: 475200,
      4: 626400,
      5: 774000,
      6: 918000,
    },
    annualPrices: {
      1: 405000,
      2: 729000,
      3: 1069000,
      4: 1405000,
      5: 1740000,
      6: 2065000,
    },
  },
  prometheus: {
    logo: prometheus,
    name: 'Prometheus',
    schoolId: 3,
    cityName: 'almaty',
    monthlyPrices: {
      1: 42000,
      2: 79800,
      3: 119700,
      4: 159600,
      5: 199500,
      6: 239400,
    },
    semiannualPrices: {
      1: 180000,
      2: 324000,
      3: 475200,
      4: 626400,
      5: 774000,
      6: 918000,
    },
    annualPrices: {
      1: 405000,
      2: 729000,
      3: 1069000,
      4: 1405000,
      5: 1740000,
      6: 2065000,
    },
  },
};

export enum SubscriptionKind {
  MONTHLY = 'monthly',
  SEMIANNUAL = 'semiannual',
  ANNUAL = 'annual',
}
