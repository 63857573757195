// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const AF: any;

declare global {
  interface Window {
    AfEventHandler: { postMessage(name: string): void };
  }
}

export function _afLogEvent(
  name: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: Object = {},
  revenue: number = 0,
  currency: string = 'KZT'
) {
  AF('pba', 'event', {
    eventType: 'EVENT',
    eventName: name,
    eventValue: data,
    eventRevenue: revenue,
    eventRevenueCurrency: currency,
  });
}

export function _afFlutterLogEvent<Type>(name: string, values: Type) {
  const data = JSON.stringify({ name, values });
  if (window.AfEventHandler) {
    window.AfEventHandler.postMessage(data);
  }
}
