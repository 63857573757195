import { FC, useEffect } from 'react';

import calendar from '@/assets/shuttle/icons/calendar.svg';
import { Select } from '@/components';
import { useAppDispatch, useAppSelector } from '@/shuttle/app/hooks';
import { DayJsValueType, addressSelector, setSelectedDate, setSelectedTime } from '@/shuttle/store/slices/addressSlice';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

import styles from './index.module.scss';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

const NewCustomerForm: FC = () => {
  const now = dayjs();
  const todayMorning = now.hour(6).startOf('hour');
  const todayEvening = now.hour(20).startOf('hour');

  const tomorrow = now.add(1, 'day');
  const tomorrowMorning = tomorrow.hour(6).startOf('hour');
  const tomorrowEvening = tomorrow.hour(20).startOf('hour');

  const selectDateValues = [
    { label: 'Сегодня', value: now },
    { label: 'Завтра', value: tomorrow },
  ];

  const { selectedDate, selectedTime } = useAppSelector(addressSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedTime({ value: now, label: now.format('hh:mm') }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setSelectedDate({ value: now, label: 'Сегодня' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDate) {
      if (selectedDate.value.isToday() && dayjs().isAfter(todayEvening)) {
        dispatch(setSelectedDate({ value: tomorrow, label: 'Завтра' }));
      }
    }
  }, [dispatch, selectedDate, todayEvening, tomorrow]);

  const onChange = async (value: Dayjs | null) => {
    if (selectedDate && value) {
      value = value.set('date', selectedDate.value.get('date'));
    }

    if (selectedDate?.value.isToday()) {
      if (value?.isBefore(now)) {
        value = now;
      } else if (value?.isBefore(todayMorning)) {
        value = todayMorning;
      } else if (value?.isAfter(todayEvening)) {
        value = todayEvening;
      }
    } else if (selectedDate?.value.isTomorrow()) {
      if (value?.isBefore(tomorrowMorning)) {
        value = tomorrowMorning;
      } else if (value?.isAfter(tomorrowEvening)) {
        value = tomorrowEvening;
      }
    }
    dispatch(setSelectedTime({ value: value!, label: value!.format('HH:MM') }));
  };

  return (
    <>
      <div className={styles.title}>Когда тебя забрать?</div>
      <div className={styles.form}>
        <div className={styles.formDate}>
          <img src={calendar} alt="calendar" className={styles.calendar} />
          <Select
            options={selectDateValues}
            value={selectedDate}
            menuClassName={styles.menuClassName}
            className={styles.select}
            onChange={(value) => {
              dispatch(setSelectedDate(value as DayJsValueType));
            }}
          />
        </div>
        <div className={styles.timepickerWrapper}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#000"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.clock}
          >
            <path
              d="M13.3 14.7L14.7 13.3L11 9.6V5H9V10.4L13.3 14.7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2167 18 14.1042 17.2208 15.6625 15.6625C17.2208 14.1042 18 12.2167 18 10C18 7.78333 17.2208 5.89583 15.6625 4.3375C14.1042 2.77917 12.2167 2 10 2C7.78333 2 5.89583 2.77917 4.3375 4.3375C2.77917 5.89583 2 7.78333 2 10C2 12.2167 2.77917 14.1042 4.3375 15.6625C5.89583 17.2208 7.78333 18 10 18Z"
              fill="black"
            />
          </svg>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
          >
            <TimePicker
              ampm={false}
              value={selectedTime?.value ?? null}
              onChange={onChange}
              minutesStep={5}
              minTime={
                selectedDate?.value.isToday() ? (now.isBefore(todayMorning) ? todayMorning : now) : tomorrowMorning
              }
              maxTime={selectedDate?.value.isToday() ? todayEvening : tomorrowEvening}
              slotProps={{
                // Targets the `InputAdornment` component.
                inputAdornment: {
                  position: 'start',
                },
              }}
              className={styles.timepicker}
            />
          </LocalizationProvider>
          <span className={styles.triangle} />
        </div>
      </div>
    </>
  );
};

export default NewCustomerForm;
