import { RootState } from '@/fake_shuttle/store/store';
import { setToaster } from '@/store/slices/toasterSlice';
import { axiosFakeAPIInstance } from '@/utils/AxiosInstance';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type tourSearchData = {
  from_address: AddressType;
  to_address: AddressType;
  phone: string;
  campaign?: string;
  date?: string;
};

export type toursSearchResponse = {
  tours: tourType[];
  from_address: AddressType;
  to_address: AddressType;
};

export const searchToursAsync = createAsyncThunk(
  'tours/SearchToursAsync',
  async (data: tourSearchData, { dispatch }) => {
    return await axiosFakeAPIInstance
      .post<toursSearchResponse>('/tours', data)
      .then((data): toursSearchResponse => data)
      .catch(() => {
        dispatch(
          setToaster({
            type: 'warning',
            message: 'Ошибка при получении списка туров',
            isVisible: true,
          })
        );
      });
  }
);

interface ToursState {
  tours: [] | tourDataType[];
  pickupStopId: number | null;
  dropOffStopId: number | null;
}

const initialState: ToursState = {
  tours: [],
  pickupStopId: null,
  dropOffStopId: null,
};

export const toursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setTours: (state, action) => {
      state.tours = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchToursAsync.fulfilled, (state, action) => {
      const response = action.payload as toursSearchResponse;
      const { tours } = response;

      if (tours.length) {
        state.tours = tours.map((tour: tourType) => {
          return {
            from_address: tour.pickup_bus_stop,
            to_address: tour.dropoff_bus_stop,
            tour,
          } as tourDataType;
        });
        state.pickupStopId = tours[0].pickup_bus_stop.id;
        state.dropOffStopId = tours[0].dropoff_bus_stop.id;
      }
    });
    builder.addCase(searchToursAsync.rejected, () => {});
  },
});

export const toursSelector = (state: RootState) => state.toursReducer;

export default toursSlice.reducer;
