import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

import { Benefits, Btn, FioForm, PhoneForm, PreLoader } from '@/components';
import { useAppDispatch, useAppSelector } from '@/fake_shuttle/app/hooks';

import { funnelSelector, getFunnelInfoAsync, setSlug } from '@/projects/fake_shuttle/store/slices/funnelSlice';

import { mainSelector } from '@/store/slices/mainSlice';
import { isValidNumber } from '@/utils/phone';
import { useNavigate } from 'react-router-dom';

import uvuLogo from '@/assets/shuttle/img/logoRed.png';
import { getOrCreateCustomerAsync } from '@/projects/fake_shuttle/store/slices/registerSlice';

const HalykAstanaRegister: FC = () => {
  const slug = 'halyk-almaty';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { phone, fio } = useAppSelector(mainSelector);
  const { name, logo, isLoading, isFIONeeded } = useAppSelector(funnelSelector);

  useEffect(() => {
    dispatch(setSlug(slug));
    dispatch(getFunnelInfoAsync({ slug }))
      .unwrap()
      .catch(() => navigate('/'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    dispatch(getOrCreateCustomerAsync({ phone, fio }))
      .unwrap()
      .then(() => navigate(`addresses`));
  };

  return (
    <div className={styles.wrapper}>
      <>
        {isLoading ? (
          <PreLoader showText showBackground />
        ) : (
          <div className={styles.main}>
            <div className={styles.head}>
              <div className={styles.logo}>
                <img src={uvuLogo} alt="UvU" />
              </div>
              {logo && (
                <>
                  <div className={styles.x}>X</div>
                  <div className={styles.company}>
                    <img src={logo} alt={name} />
                  </div>
                </>
              )}
            </div>

            {isFIONeeded && <FioForm circle={false} />}
            <PhoneForm circle={false} />

            <Benefits withBus />

            <div>
              <Btn
                color="black"
                customClassName={styles.btn}
                disabled={isLoading || !isValidNumber(phone) || (isFIONeeded && !fio)}
                onClick={onSubmit}
                isFixedBottom={true}
              >
                Продолжить
              </Btn>
            </div>
          </div>
        )}
        ;
      </>
      <div className={styles.law}>
        <p>Нажимая "Продолжить", вы соглашаетесь с</p>
        <p>
          <Link to="/privacy">Политикой конфеденциальности</Link>
        </p>
        <p>
          <Link to="/oferta">Договором оферты</Link>
        </p>
      </div>
    </div>
  );
};

export default HalykAstanaRegister;
