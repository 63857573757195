import back from '@/assets/shuttle/icons/backBlack.svg';
import logo from '@/assets/shuttle/img/logo.png';
import { Btn } from '@/components';
import Tour from '@/components/shared/molecules/TourSlide';
import TourMap from '@/components/shuttle/molecules/TourMap';
import { useAppSelector } from '@/shuttle/app/hooks';
import { buySubscriptionAsync, paymentSelector } from '@/shuttle/store/slices/paymentSlice';
import { toursSelector } from '@/shuttle/store/slices/toursSlice';
import { mainSelector } from '@/store/slices/mainSlice';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

import endPointPinSrc from '@/assets/shuttle/icons/end_pin.svg';
import ridePointPinSrc from '@/assets/shuttle/icons/ride_pin.svg';
import startPointPinSrc from '@/assets/shuttle/icons/start_pin.svg';
import { updateTourResponseAsync } from '@/projects/shuttle/store/slices/searchResponseSlice';
import { useAppDispatch } from '@/shuttle/app/hooks';
import { parseNumber } from '@/utils/phone';

const TourForNewCustomerController: FC = () => {
  const dispatch = useAppDispatch();
  const { isMobile, phone } = useAppSelector(mainSelector);
  const { subscriptionKind } = useAppSelector(paymentSelector);
  const { tours } = useAppSelector(toursSelector);
  const navigate = useNavigate();
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(true);
  const [currentTour, setCurrentTour] = useState<tourDataType | null>(null);

  useEffect(() => {
    if (!tours.length) {
      navigate('/addresses');
    }
  }, [tours, navigate]);

  useEffect(() => {
    setCurrentTour(tours[0]);
  }, [tours]);

  const handleSubmit = () => {
    // Adds start_date only if selectedDateValue is not null.
    const data = Object.assign({
      tour_id: Number(currentTour?.tour.id),
      phone: parseNumber(phone),
      passenger_count: 1,
      kind: subscriptionKind,
      pickup_point_id: currentTour?.tour.pickup_bus_stop.id,
      delivery_point_id: currentTour?.tour.dropoff_bus_stop.id,
      is_mobile: isMobile,
    });

    dispatch(buySubscriptionAsync(data));
  };

  return (
    <div className={styles.main}>
      {currentTour ? <TourMap data={currentTour} /> : null}
      <div className={styles.info}>
        <Btn color="white" customClassName={styles.backBtn} onClick={() => navigate(-1)}>
          <img src={back} alt="back" />
        </Btn>
        {currentTour && (
          <div className={styles.tourBlock}>
            <div className={styles.tour}>
              <Tour data={currentTour} showDate={true} />
            </div>
            {currentTour.tour.driver && (
              <div className={styles.driverWrapper}>
                <div className={styles.driver}>
                  <div className={styles.driverPhoto}>
                    {currentTour.tour.driver.photo_url ? (
                      <img src={currentTour.tour.driver.photo_url} alt="driver" className={styles.driverPhotoImg} />
                    ) : (
                      <img src={logo} alt="driver" className={styles.driverPhotoImg} />
                    )}
                  </div>
                  <div className={styles.driverText}>
                    <div className={styles.driverTextName}>{currentTour.tour.driver.name}</div>
                    <div className={styles.driverTextCar}>{currentTour.tour.driver.car_name}</div>
                    <div className={styles.driverTextNumberPlate}>{currentTour.tour.driver.car_number_plate}</div>
                  </div>
                  <div className={styles.driverTrip}>
                    <div className={styles.driverTripTitle}>Цена</div>
                    <div className={styles.driverTripPrice}>{currentTour.tour.one_trip_price} тг</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <Btn
          color="black"
          disabled={!currentTour}
          customClassName={styles.nextBtn}
          onClick={() => {
            dispatch(updateTourResponseAsync({ id: currentTour!.tour.response_id }));
            handleSubmit();
          }}
        >
          Заказать
        </Btn>
        {isPopupVisible && (
          <div className={styles.popup}>
            <div className={styles.popupCloseBtn} onClick={() => setIsPopupVisible(false)}>
              &times;
            </div>
            <div className={styles.popupItem}>
              <div className={styles.popupItemIcons}>
                <img src={startPointPinSrc} alt="Start Point" />
                <img src={endPointPinSrc} alt="End Point" className={styles.popupItemIconsSecond} />
              </div>
              <div>Введенные тобой адреса</div>
            </div>
            <div className={styles.popupItem}>
              <div className={styles.popupItemIcons}>
                <img src={ridePointPinSrc} alt="Ride Point" className={styles.popupItemIconsWithLeftMargin} />
              </div>
              <div>Остановка, где тебя будет ждать водитель в указанное время</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TourForNewCustomerController;
