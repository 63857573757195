import { RootState } from '@/fake_shuttle/store/store';
import { axiosFakeAPIInstance } from '@/utils/AxiosInstance';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

type Location = {
  lat: number;
  lng: number;
};

type AddressType = {
  id: number;
  name: string;
  location: Location;
};

type funnelData = {
  slug: string;
};

export enum funnelKind {
  SUBSCRIPTION = 'subscription',
  SHUTTLE_FORM = 'shuttle_form',
}

export type funnelInfoResponse = {
  name: string;
  logo: string;
  kind: funnelKind;
  is_fio_needed: boolean;
  input_placeholder: string;
  pickup_addresses: AddressType[];
  dropoff_addresses: AddressType[];
  subscription_start_date: string | null;
  city_center_coordinates: Location;
};

export const getFunnelInfoAsync = createAsyncThunk(
  'funnel/getFunnelInfo',
  async (data: funnelData): Promise<funnelInfoResponse> => {
    return await axiosFakeAPIInstance.get<funnelInfoResponse>(`/shuttle-funnels/${data.slug}`).then((data) => data);
  }
);

export type funnelState = {
  name: string;
  slug: string;
  logo: string;
  kind: funnelKind;
  isFIONeeded: boolean;
  inputPlaceholder: string;
  pickupAddresses: AddressType[];
  dropoffAddresses: AddressType[];
  cityCenterCoordinates: Location | null;
  subscriptionStartDate: string | null;
  isLoading: boolean;
};

const initialState: funnelState = {
  name: '',
  slug: '',
  logo: '',
  kind: funnelKind.SUBSCRIPTION,
  isFIONeeded: false,
  inputPlaceholder: '',
  pickupAddresses: [],
  dropoffAddresses: [],
  subscriptionStartDate: null,
  cityCenterCoordinates: null,
  isLoading: true,
};

export const funnelSlice = createSlice({
  name: 'funnel',
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setSlug: (state, action: PayloadAction<string>) => {
      state.slug = action.payload;
    },
    setLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload;
    },
    setPickupAddresses: (state, action: PayloadAction<AddressType[]>) => {
      state.pickupAddresses = action.payload;
    },
    setDropoffAddresses: (state, action: PayloadAction<AddressType[]>) => {
      state.dropoffAddresses = action.payload;
    },
    setSubscriptionStartDate: (state, action: PayloadAction<string>) => {
      state.subscriptionStartDate = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFunnelInfoAsync.fulfilled, (state, action: PayloadAction<funnelInfoResponse>) => {
      state.isLoading = false;
      const response = action.payload as funnelInfoResponse;

      state.name = response.name;
      state.logo = response.logo;
      state.inputPlaceholder = response.input_placeholder;
      state.cityCenterCoordinates = response.city_center_coordinates;
      state.pickupAddresses = response.pickup_addresses;
      state.dropoffAddresses = response.dropoff_addresses;
      state.subscriptionStartDate = response.subscription_start_date;
      state.kind = response.kind;
      state.isFIONeeded = response.is_fio_needed;
    });
    builder.addCase(getFunnelInfoAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setName,
  setSlug,
  setLogo,
  setPickupAddresses,
  setDropoffAddresses,
  setIsLoading,
  setSubscriptionStartDate,
} = funnelSlice.actions;

export const funnelSelector = (state: RootState) => state.funnelReducer;

export default funnelSlice.reducer;
